import React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Tooltip from '@mui/material/Tooltip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CustomTablePagination from '../../PanelCustomTablePagination';

interface TableExamesHistopatologiaProps {
  data: Array<{
    id: string | null;
    nome: string;
    totaisIntraColpo: number;
    histopatologiasRealizadas: number;
    histopatologiasARealizar: number;
    histopatologiasPecaCirurgica?: number;
    histopatologiasRealizadasSemVinculacao?: number;
  }>;
  totals: {
    totalIntraColpo?: number;
    totalHistopatologiasRealizadas?: number;
    totalHistopatologiasARealizar?: number;
  };
  origemSelecionada: string;
  totalItems: number;
  currentPage: number;
  itemsPerPage: number;
  onPageChange: (newPage: number) => void;
  onRowsPerPageChange: (newRowsPerPage: number) => void;
  localizacaoType: string;
}

export default function TableExamesHistopatologia({
  data,
  totals,
  origemSelecionada,
  totalItems,
  currentPage,
  itemsPerPage,
  onPageChange,
  onRowsPerPageChange,
  localizacaoType,
}: TableExamesHistopatologiaProps) {
  const columns = [
    { id: 'nome', label: localizacaoType || 'Localização', minWidth: 170 },
    {
      id: 'totaisIntraColpo',
      label: origemSelecionada === 'Biópsia'
        ? 'Mulheres com Colposcopia com Achados Menores, Maiores ou Segestivo de Invasão'
        : 'Mulheres com Tratamento LIEAG',
      minWidth: 170,
      width: 292,
    },
    {
      id: 'histopatologiasRealizadas',
      label: 'Histopatologia Realizadas',
      minWidth: 170,
    },
    {
      id: 'histopatologiasARealizar',
      label: 'Histopatologia a Realizar',
      minWidth: 170,
    },
  ];

  const handleChangePage = (event: unknown, newPage: number) => {
    onPageChange(newPage + 1);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    onRowsPerPageChange(newRowsPerPage);
    onPageChange(1);
  };

  const renderHistopatologiasRealizadas = (row: any) => {
    if (origemSelecionada === 'Peça Cirúrgica') {
      if (row.histopatologiasPecaCirurgica === row.histopatologiasRealizadas) {
        return row.histopatologiasRealizadas;
      } else if (row.histopatologiasRealizadas === 0) {
        return (
          <span style={{ display: 'flex', alignItems: 'center' }}>
            <span style={{ color: 'red', marginRight: '4px' }}>
              {row.histopatologiasRealizadas}
            </span>
            <Tooltip title="Existem Histopatologia de peça cirúrgica registradas, porém elas não atendem ao critério de cadastramento Pós-tratamento LIEAG">
              <InfoOutlinedIcon
                style={{
                  fontSize: '24px',
                  color: 'gray',
                  position: 'relative',
                  top: '-1px',
                  marginLeft: '2px',
                }}
              />
            </Tooltip>
          </span>
        );
      } else if (
        row.histopatologiasPecaCirurgica !== row.histopatologiasRealizadas &&
        row.histopatologiasRealizadas > 0
      ) {
        return (
          <span style={{ display: 'flex', alignItems: 'center' }}>
            <span style={{ color: '#FFD700', marginRight: '4px' }}>
              {row.histopatologiasRealizadas}
            </span>
            <Tooltip title="Existem Histopatologia de peça cirúrgica registradas, porém elas não atendem ao critério de cadastramento Pós-tratamento LIEAG">
              <InfoOutlinedIcon
                style={{
                  fontSize: '24px',
                  color: 'gray',
                  position: 'relative',
                  top: '-1px',
                  marginLeft: '2px',
                }}
              />
            </Tooltip>
          </span>
        );
      }
    } else if (origemSelecionada === 'Biópsia') {
      if (row.histopatologiasRealizadasSemVinculacao > row.histopatologiasRealizadas) {
        const diff = row.histopatologiasRealizadasSemVinculacao - row.histopatologiasRealizadas;
        return (
          <span style={{ display: 'flex', alignItems: 'center' }}>
            <span style={{ color: '#FFA500', marginRight: '4px' }}>
              {row.histopatologiasRealizadas}
            </span>
            <Tooltip title={`Existem ${row.histopatologiasRealizadasSemVinculacao} Histopatologias de biopsia registradas, porém elas não se relacionam com Mulheres com Colposcopia com Achados Menores, Maiores ou Segestivo de Invasão.`}>
              <InfoOutlinedIcon
                style={{
                  fontSize: '24px',
                  color: 'gray',
                  position: 'relative',
                  top: '-1px',
                  marginLeft: '2px',
                }}
              />
            </Tooltip>
          </span>
        );
      }
    }
    return row.histopatologiasRealizadas;
  };

  return (
    <Box className="table-container">
      <Paper sx={{ width: '100%', mb: 2, pt: 2 }}>
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size="medium"
            className="table"
          >
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    style={{
                      minWidth: column.minWidth,
                      width: column.width,
                      fontWeight: 'bold',
                    }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={4}>Sem Resultados</TableCell>
                </TableRow>
              ) : (
                <>
                  {data.map((row, index) => (
                    <TableRow hover tabIndex={-1} key={index}>
                      <TableCell component="th" scope="row">
                        {row.nome}
                      </TableCell>
                      <TableCell>{row.totaisIntraColpo}</TableCell>
                      <TableCell>{renderHistopatologiasRealizadas(row)}</TableCell>
                      <TableCell
                        style={{
                          color: row.histopatologiasARealizar < 0 ? 'red' : 'inherit',
                        }}
                      >
                        {row.histopatologiasARealizar}
                      </TableCell>
                    </TableRow>
                  ))}
                  <TableRow>
                    <TableCell align="right" style={{ fontWeight: 'bold' }}>
                      Total
                    </TableCell>
                    <TableCell style={{ fontWeight: 'bold' }}>
                      {totals.totalIntraColpo ?? 0}
                    </TableCell>
                    <TableCell style={{ fontWeight: 'bold' }}>
                      {totals.totalHistopatologiasRealizadas ?? 0}
                    </TableCell>
                    <TableCell style={{ fontWeight: 'bold' }}>
                      {totals.totalHistopatologiasARealizar ?? 0}
                    </TableCell>
                  </TableRow>
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <CustomTablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={totalItems}
          page={currentPage - 1}
          rowsPerPage={itemsPerPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage={'Itens por página'}
          labelDisplayedRows={({ from, to, count }) =>
            `${from}–${to} de ${count !== -1 ? count : `mais do que ${to}`}`
          }
        />
      </Paper>
    </Box>
  );
}