import moment from 'moment';

const useSetPayload = ({
  watch,
  estadosSelected,
  municipiosSelected,
  estabelecimentosSelected,
  dtColetaIni,
  dtColetaFinal,
  dtResultadoIni,
  dtResultadoFinal,
}) => {
  const setPayload = () => {
    return new Promise((resolve) => {
      const formValues = watch();

      const formatDate = (date) => {
        if (!date) return undefined;
        return moment(date, 'DD-MM-YYYY').format('YYYY-MM-DD');
      };

      const getDatesForProcedure = () => {
        if (!formValues.procedimento_coleta) return {};

        const procedureMap = {
          TESTE_HPV: {
            coleta: ['dataColetaHpvInicio', 'dataColetaHpvFim'],
            resultado: ['dataResultadoHpvInicio', 'dataResultadoHpvFim'],
          },
          CITOLOGIA: {
            resultado: [
              'dataResultadoCitologiaInicio',
              'dataResultadoCitologiaFim',
            ],
          },
          COLPOSCOPIA: {
            coleta: ['dataColposcopiaInicio', 'dataColposcopiaFim'],
          },
          HISTOPATOLOGIA: {
            coleta: [
              'dataColetaHistopatologiaInicio',
              'dataColetaHistopatologiaFim',
            ],
            resultado: [
              'dataResultadoHistopatologiaInicio',
              'dataResultadoHistopatologiaFim',
            ],
          },
        };

        const dates = {};
        const procedure = procedureMap[formValues.procedimento_coleta];

        if (procedure?.coleta) {
          dates[procedure.coleta[0]] = formatDate(dtColetaIni);
          dates[procedure.coleta[1]] = formatDate(dtColetaFinal);
        }

        if (procedure?.resultado) {
          dates[procedure.resultado[0]] = formatDate(dtResultadoIni);
          dates[procedure.resultado[1]] = formatDate(dtResultadoFinal);
        }

        return dates;
      };

      const getAtendimentoTesteHpv = () => {
        if (formValues.atendimentoTesteHpv === 'Sim') {
          return 'REALIZADO';
        }
        if (formValues.atendimentoTesteHpv === 'Não') {
          return formValues.atendimento_status;
        }
        return undefined;
      };

      // const parseNumericValue = (value) => {
      //   const parsed = parseInt(value);
      //   return isNaN(parsed) ? undefined : parsed;
      // };

      const payload = {
        estados:
          estadosSelected.length > 0 ? estadosSelected.join(', ') : undefined,
        municipios:
          municipiosSelected.length > 0
            ? municipiosSelected.join(', ')
            : undefined,
        estabelecimentoSaudeId:
          estabelecimentosSelected.length > 0
            ? estabelecimentosSelected.join(', ')
            : undefined,

        atendimentoTesteHpv: getAtendimentoTesteHpv(),
        resultadoTesteHpv: formValues.resultado_teste_hpv,
        resultadoCitologia: formValues.resultado_citologia,

        tipoAchados: formValues.tipos_achados,
        realizarBiopsia: formValues.realizar_biopsia,
        origem_histopatologia: formValues.origem_histopatologia,

        preNeoplasicaEscamosa: formValues.pre_neoplasico_escamosa,
        preNeoplasicaGlandular: formValues.pre_neoplasico_glandular,
        indicacaoEzt: formValues.indicacao_ezt,

        search: formValues.search || undefined,
        page: formValues.page || 1,
        size: formValues.size || 25,

        ...getDatesForProcedure(),
      };

      const cleanPayload = Object.entries(payload).reduce(
        (acc, [key, value]) => {
          if (value !== undefined && value !== null && value !== '') {
            acc[key] = value;
          }
          return acc;
        },
        {}
      );

      resolve(cleanPayload);
    });
  };

  return { setPayload };
};

export default useSetPayload;
