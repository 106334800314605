import React from 'react';
import { Box, Typography, Paper, useMediaQuery, useTheme } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import Tooltip from '@mui/material/Tooltip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import styles from './styles.module.css';
import { DataSource } from '../../../hpv/components/dataSource';

interface DataRow {
  label: string;
  citologia_count: number;
  citologia_count_total: number;
  hpv_outros_count: number;
  citologia_realizar: number;
}

interface TableResultsProps {
  data: DataRow[];
  totalItems: number;
  onPageChange: (newPage: number) => void;
  onRowsPerPageChange: (newRowsPerPage: number) => void;
  page: number;
  rowsPerPage: number;
  valueInputPeriod?: string;
  total: {
    hpv: number;
    citologia: number;
    citologia_total: number;
    realizar: number;
  };
  listType: 'estados' | 'municipios' | 'estabelecimentos';
}

function Card({ row, listType }: { row: DataRow; listType: string }) {
  const getColumnName = () => {
    switch (listType) {
      case 'estados':
        return 'Estado';
      case 'municipios':
        return 'Município';
      case 'estabelecimentos':
        return 'Estabelecimento';
      default:
        return 'Local';
    }
  };

  const renderCitologiaCount = (count: number, total: number) => {
    if (count < total) {
      return (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
          <Typography fontSize="14px" sx={{ color: '#FFA500' }}>
            {count}
          </Typography>
          <Tooltip title={`Existem ${total} citologias cadastradas, mas elas não se correlacionam com as mulheres com HPV Outros da tabela`}>
            <InfoOutlinedIcon sx={{ fontSize: '20px', color: 'gray' }} />
          </Tooltip>
        </Box>
      );
    }
    return <Typography fontSize="14px">{count}</Typography>;
  };

  return (
    <Box py="16px" className={styles.cardTableMobile}>
      <Typography fontSize="14px" fontWeight="bold">
        {getColumnName()}
      </Typography>
      <Typography fontSize="14px">{row.label}</Typography>

      <Typography fontSize="14px" fontWeight="bold">
        Mulheres com HPV Outros
      </Typography>
      <Typography fontSize="14px">{row.hpv_outros_count}</Typography>

      <Typography fontSize="14px" fontWeight="bold">
        Citologias Realizadas
      </Typography>
      {renderCitologiaCount(row.citologia_count, row.citologia_count_total)}

      <Typography fontSize="14px" fontWeight="bold">
        Citologias a Realizar
      </Typography>
      <Typography fontSize="14px">{row.citologia_realizar}</Typography>
    </Box>
  );
}

export function TableResults({
  data,
  totalItems,
  onPageChange,
  onRowsPerPageChange,
  page,
  rowsPerPage,
  valueInputPeriod,
  total,
  listType,
}: TableResultsProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    onPageChange(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    onRowsPerPageChange(parseInt(event.target.value, 10));
  };

  const getColumnName = () => {
    switch (listType) {
      case 'estados':
        return 'Estados';
      case 'municipios':
        return 'Municípios';
      case 'estabelecimentos':
        return 'Estabelecimentos';
      default:
        return 'Local';
    }
  };

  const renderCitologiaCount = (count: number, total: number, isTotalRow: boolean = false) => {
    if (count < total) {
      return (
        <Box sx={{ 
          display: 'flex', 
          alignItems: 'center',
          gap: '8px',
        }}>
          <Box sx={{ 
            width: '60px',
            textAlign: 'right',
            color: '#FFA500',
            fontWeight: isTotalRow ? 'bold' : 'normal'
          }}>
            {count}
          </Box>
          <Tooltip title={`Existem ${total} citologias cadastradas, mas elas não se correlacionam com as mulheres com HPV Outros da tabela`}>
            <InfoOutlinedIcon sx={{ fontSize: '20px', color: 'gray', flexShrink: 0 }} />
          </Tooltip>
        </Box>
      );
    }
    return (
      <Box sx={{ 
        width: '60px',
        textAlign: 'right',
        fontWeight: isTotalRow ? 'bold' : 'normal',
      }}>
        {count}
      </Box>
    );
  };

  const renderNumber = (value: number, isBold: boolean = false) => (
    <Box sx={{ width: '60px', textAlign: 'right', fontWeight: isBold ? 'bold' : 'normal' }}>
      {value}
    </Box>
  );

  return (
    <div className={styles.container}>
      <Typography variant="h5" fontWeight="bold" fontSize="20px">
        Exames de Citologia - Reflexo (após teste de HPV Positivo)
        <br />
        Mulheres com HPV Outros x Citologias Realizadas
      </Typography>
      <Box sx={{ margin: '8px 0', fontSize: '12px', color: '#9E9E9E' }}>
        {valueInputPeriod === 'Todos' || !valueInputPeriod
          ? 'Todos os períodos'
          : valueInputPeriod}
      </Box>

      <Paper sx={{ width: '100%', mb: 2, pt: 2 }}>
        {isMobile ? (
          <>
            {data.map((row, index) => (
              <Card key={index} row={row} listType={listType} />
            ))}
            <Card
              row={{
                label: 'Total',
                hpv_outros_count: total.hpv,
                citologia_count: total.citologia,
                citologia_count_total: total.citologia_total,
                citologia_realizar: total.realizar,
              }}
              listType={listType}
            />
          </>
        ) : (
          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size="medium"
            >
              <TableHead>
                <TableRow>
                  <TableCell style={{ fontWeight: 'bold' }}>
                    {getColumnName()}
                  </TableCell>
                  <TableCell style={{ fontWeight: 'bold' }}>
                    Mulheres com HPV Outros
                  </TableCell>
                  <TableCell style={{ fontWeight: 'bold' }}>
                    Citologias Realizadas
                  </TableCell>
                  <TableCell style={{ fontWeight: 'bold' }}>
                    Citologias a Realizar
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((row, index) => (
                  <TableRow hover tabIndex={-1} key={index}>
                    <TableCell>{row.label}</TableCell>
                    <TableCell>
                      {renderNumber(row.hpv_outros_count)}
                    </TableCell>
                    <TableCell>
                      {renderCitologiaCount(row.citologia_count, row.citologia_count_total)}
                    </TableCell>
                    <TableCell>
                      {renderNumber(row.citologia_realizar)}
                    </TableCell>
                  </TableRow>
                ))}
                <TableRow>
                  <TableCell align="right" style={{ fontWeight: 'bold' }}>
                    Total
                  </TableCell>
                  <TableCell>
                    {renderNumber(total.hpv, true)}
                  </TableCell>
                  <TableCell>
                    {renderCitologiaCount(total.citologia, total.citologia_total, true)}
                  </TableCell>
                  <TableCell>
                    {renderNumber(total.realizar, true)}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        )}

        <TablePagination
          component="div"
          count={totalItems}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[10, 25, 50]}
          labelRowsPerPage={'Itens por página'}
          labelDisplayedRows={({ from, to, count }) => {
            return `${from}–${to} de ${
              count !== -1 ? count : `mais do que ${to}`
            }`;
          }}
          nextIconButtonProps={{ 'aria-label': 'Próxima página' }}
          backIconButtonProps={{ 'aria-label': 'Página anterior' }}
          showFirstButton={false}
          showLastButton={false}
        />
      </Paper>
      <br />
      <DataSource />
    </div>
  );
}