import React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Tooltip from '@mui/material/Tooltip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CustomTablePagination from '../../../../../../components/PanelCustomTablePagination';

interface TableCitoColpoProps {
  data: Array<{
    id: number | null;
    nome: string;
    citologiasAlteradas: number;
    colposcopiasRealizadas: number;
    colposcopiasRealizadasSemRelacao: number;
    colposcopiasARealizar: number;
  }>;
  totals: {
    totalCitologiasAlteradas: number;
    totalColposcopiasRealizadas: number;
    totalColposcopiasRealizadasSemRelacao: number;
    totalColposcopiasARealizar: number;
  };
  totalItems: number;
  currentPage: number;
  itemsPerPage: number;
  onPageChange: (newPage: number) => void;
  onRowsPerPageChange: (newRowsPerPage: number) => void;
  localizacaoType: string;
}

export default function TableCitoColpo({
  data,
  totals,
  totalItems,
  currentPage,
  itemsPerPage,
  onPageChange,
  onRowsPerPageChange,
  localizacaoType,
}: TableCitoColpoProps) {
  const columns = [
    { id: 'nome', label: localizacaoType || 'Localidade', minWidth: 170 },
    {
      id: 'citologiasAlteradas',
      label: 'Mulheres com Citologia Insatisfatória ou Com Alterações Epteliais',
      minWidth: 170,
      width: 292,
    },
    {
      id: 'colposcopiasRealizadas',
      label: 'Colposcopias Realizadas',
      minWidth: 170,
    },
    {
      id: 'colposcopiasARealizar',
      label: 'Colposcopias a Realizar',
      minWidth: 170,
    },
  ];

  const handleChangePage = (event: unknown, newPage: number) => {
    onPageChange(newPage + 1);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    onRowsPerPageChange(parseInt(event.target.value, 10));
  };

  const renderNumber = (value: number, isBold: boolean = false) => (
    <Box sx={{ width: '60px', textAlign: 'right', fontWeight: isBold ? 'bold' : 'normal' }}>
      {value}
    </Box>
  );

  const renderColposcopiasRealizadas = (value: number, semRelacao: number, isTotalRow: boolean = false) => {
    if (value < semRelacao) {
      return (
        <Box sx={{ 
          display: 'flex', 
          alignItems: 'center',
          gap: '8px',
        }}>
          <Box sx={{ 
            width: '60px',
            textAlign: 'right',
            color: '#FFA500',
            fontWeight: isTotalRow ? 'bold' : 'normal'
          }}>
            {value}
          </Box>
          <Tooltip title={`Existem ${semRelacao} colposcopias cadastradas, mas elas não se correlacionam com as Citologias da Tabela`}>
            <InfoOutlinedIcon sx={{ fontSize: '20px', color: 'gray', flexShrink: 0 }} />
          </Tooltip>
        </Box>
      );
    }
    return renderNumber(value, isTotalRow);
  };

  return (
    <Box className="table-container" sx={{ display: 'flex', justifyContent: 'center' }}>
      <Paper sx={{ width: '100%', mb: 2, pt: 2 }}>
        <TableContainer>
          <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={'medium'} className="table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    style={{
                      minWidth: column.minWidth,
                      width: column.width,
                      fontWeight: 'bold',
                    }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={4}>Sem Resultados</TableCell>
                </TableRow>
              ) : (
                <>
                  {data.map((row, index) => (
                    <TableRow hover tabIndex={-1} key={index}>
                      <TableCell component="th" scope="row">
                        {row.nome}
                      </TableCell>
                      <TableCell>
                        {renderNumber(row.citologiasAlteradas)}
                      </TableCell>
                      <TableCell>
                        {renderColposcopiasRealizadas(
                          row.colposcopiasRealizadas,
                          row.colposcopiasRealizadasSemRelacao
                        )}
                      </TableCell>
                      <TableCell>
                        {renderNumber(row.colposcopiasARealizar)}
                      </TableCell>
                    </TableRow>
                  ))}
                  <TableRow>
                    <TableCell align="right" style={{ fontWeight: 'bold' }}>
                      Total
                    </TableCell>
                    <TableCell>
                      {renderNumber(totals.totalCitologiasAlteradas, true)}
                    </TableCell>
                    <TableCell>
                      {renderColposcopiasRealizadas(
                        totals.totalColposcopiasRealizadas,
                        totals.totalColposcopiasRealizadasSemRelacao,
                        true
                      )}
                    </TableCell>
                    <TableCell>
                      {renderNumber(totals.totalColposcopiasARealizar, true)}
                    </TableCell>
                  </TableRow>
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <CustomTablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={totalItems}
          page={currentPage - 1}
          rowsPerPage={itemsPerPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage={'Itens por página'}
          labelDisplayedRows={({ from, to, count }) =>
            `${from}–${to} de ${count !== -1 ? count : `mais do que ${to}`}`
          }
        />
      </Paper>
    </Box>
  );
}